import React from "react"
import Helmet from "react-helmet"
import Navigation from "../components/Navigation"
import {Link} from "gatsby"

import * as LawPagesModule from '../styles/law-pages.module.css'

export default () => (
  <>
    <Helmet>
      <title>Impressum - Steimers Strandbar</title>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="description"
            content="Die Strandbar auf der Nordseeinsel Juist"/>
    </Helmet>
    <Navigation/>
    <div style={{height: '100vh'}} className={LawPagesModule.container}>
        <div className={LawPagesModule.textContainer}>
          <h1>Impressum</h1>
          <h3>Steimers Strandbar</h3>
          <p>
            Am Schoolpad Strand<br/>
            26571 Juist
          </p>
          <h3>Vertreten durch</h3>
          <p>
            Thomas Steimer
          </p>
          <h3>Kontakt</h3>
          <a>
            info@steimers-strandbar.de
          </a>
          <h3>Umsatzsteuer-ID</h3>
          <p>
            DE 117357378
          </p>
          <Link to={'/'}>Zurück zur Hauptseite</Link>
        </div>
    </div>
  </>
)
